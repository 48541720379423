<template>
  <div class="common">
    <div class="common-content">
      <section class="common-head">
        <div>
          <img src="@/assets/logo.png" alt class="inline-item common-logo" title="回到首页" @click="$router.push('/home')">
          <span class="inline-item co-name"></span>
        </div>
        <div class="fixed-title" :class="{show: showFixedTitle}">
          <div class="title-text" @click="$router.push('/home')">Black Square Design Lab</div>
        </div>
        <div class="menu-flex">
          <div class="menu-content">
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'home'}"  @click="toJump('/')"><span class="menu-item-zh">项目</span>Works+</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'about'}" @click="toJump('/about')"><span class="menu-item-zh">我们</span>About us</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'jobs'}"  @click="toJump('/jobs')"><span class="menu-item-zh">工作</span>Jobs</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'cont'}"  @click="toJump('/cont')"><span class="menu-item-zh">联系</span>Contact</div>
          </div>
          <a href="https://weibo.com/u/3712580061" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/weibo.png" alt class="content-weibo">
          </a>
        </div>
      </section>

      <slot></slot>
    </div>

    <section class="common-foot">
      <section class="common-head">
        <div>
          <img src="@/assets/logo-white.png" alt class="inline-item common-logo" title="回到首页" @click="$router.push('/home')">
          <span class="inline-item co-name"></span>
        </div>
        
        <div class="menu-flex">
          <div class="menu-content foot-menu">
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'home'}"  @click="toJump('/')"><span class="menu-item-zh">项目</span>Works+</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'about'}" @click="toJump('/about')"><span class="menu-item-zh">我们</span>About us</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'jobs'}"  @click="toJump('/jobs')"><span class="menu-item-zh">工作</span>Jobs</div>
            <div class="menu-item" :class="{'menu-item-disabled': $route.name == 'cont'}"  @click="toJump('/cont')"><span class="menu-item-zh">联系</span>Contact</div>
          </div>
          <a href="https://weibo.com/u/3712580061" target="_blank" rel="noopener noreferrer">
            <img src="@/assets/weibo-white.png" alt class="content-weibo">
          </a>
        </div>
      </section>
      <div class="foot-left">
        Copyright@2013 | 南京黑面设计咨询有限 | 版权所有 | 苏ICP备13042981号-1
      </div>
    </section>

  </div>
</template>

<script>
import { constantRouter } from "@/router";

export default {
  name: "Common",
  data() {
    return {
      constantRouter: constantRouter,
      logo: require("@/assets/logo.png"),
      showFixedTitle: false
    };
  },
  watch: {
    '$route.name'(newValue, oldValue) {
      document.querySelector('.el-scrollbar__wrap') && (document.querySelector('.el-scrollbar__wrap').scrollTop = 0)
    }
  },
  mounted () {
    document.querySelector('.el-scrollbar__wrap').onscroll = () => {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      const scrollTop = document.querySelector('.el-scrollbar__wrap').scrollTop
      this.showFixedTitle = scrollTop > 70
    }
  },
  methods: {
    toJump(path) {
      this.$router.push({ path })
    }
  },
};
</script>

<style lang="less" scoped>
.common {
  margin: auto;
  .common-content {
    min-height: calc(100vh - 315px);
  }
  .common-head {
    margin: auto;
    padding: 60px 60px 0 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    .fixed-title {
      position: fixed;
      height: 50px;
      background: #ffffff;
      z-index: 100;
      margin: auto;
      color: #222222;
      font-size: 22px;
      font-weight: bold;
      top: -50px;
      left: 0;
      padding-left: 40px;
      opacity: 0;
      transition: opacity,top 0.3s linear;
      &.show {
        opacity: 1;
        top: 0;
        width: 100%;
      }
      .title-text {
        height: 50px;
        line-height: 45px;
        width: 100%;
        font-family: Arial;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .inline-item {
      vertical-align: middle;
    }
    .common-logo {
      height: 50px;
      width: auto;
      cursor: pointer;
    }
    .co-name {
      font-size: 30px;
      color: #0078ff;
      margin: auto;
      margin-left: 20px;
      font-weight: bold;
    }
  }
  .common-content {
    background: #ffffff;
  }

  .common-foot {
    background: #333333;
    color: #ffffff;
    height: 520px;
    position: relative;
    .common-head {
      padding-top: 80px;
    }
    .foot-left {
      font-size: 14px;
      color: #EAEAEA;
      font-weight: 400;
      position: absolute;
      bottom: 70px;
      left: 60px;
    }
  }

  .menu-flex {
    display: flex;
  }

  .menu-content {
    font-weight: 800;
    font-size: 28px;
    color: #4D4F52;
    line-height: 49px;
    font-weight: 500;
    font-family: Arial;
    margin-right: 149px;
    position: relative;
    .menu-item {
      cursor: pointer;
      display: flex;
      &.menu-item-disabled {
        cursor: not-allowed;
        color: #999999;
      }
      .menu-item-zh {
        font-size: 24px;
        margin-right: 5px;
      }
    }
    &:after {
      content: '';
      position: absolute;
      right: -66px;
      top: 14px;
      background: #555555;
      height: 24px;
      width: 1px;
    }
  }
  .content-weibo {
    width: 27px;
    height: 27px;
    margin-top: 14px;
  }
  .foot-menu {
    color: #ffffff;
  }
  .foot-left {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #EAEAEA;
    font-weight: 400;
  }
}
</style>
